<template>
    <v-container fluid>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="10">
                    <v-btn icon
                           light
                           @click="$router.go(-1)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h1 v-if="emailAccountId > 0">{{emailAccount.name}}</h1>
                    <h1 v-else>{{ $t('common_create') }}</h1>
                </v-col>

                <v-col cols="2" align="end">
                    <v-btn color="success"
                           elevation="2"
                           large
                           @click="saveEmailAccount()">
                        {{ $t('common_save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-card tile color="demex_yellow">
            <v-toolbar dense
                       dark
                       max-height="50"
                       color="grey"> 
                <v-toolbar-items>
                    <div class="text-center ml-5 mt-3" v-if="emailAccountId > 0">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs"
                                        v-on="on"
                                        dense>
                                    mdi-dots-vertical
                                </v-icon>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn color="error" tile @click="deleteDialog = !deleteDialog">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                            {{ $t('common_delete') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="mt-3">
                <v-text-field v-model="emailAccount.displayName" label="displayName"></v-text-field>
                <v-text-field v-model="emailAccount.email" label="email"></v-text-field>
                <v-text-field v-model="emailAccount.host" label="host"></v-text-field>
                <v-text-field v-model="emailAccount.port" type="number" label="port"></v-text-field>
                <v-text-field v-model="emailAccount.username" label="username"></v-text-field>
                <v-text-field v-model="emailAccount.password" label="password"></v-text-field>

                <v-checkbox v-model="emailAccount.enableSsl"
                            label="enableSsl">
                </v-checkbox>
                <v-checkbox v-model="emailAccount.useDefaultCredentials"
                            label="useDefaultCredentials">
                </v-checkbox>
            </v-card-text>
            <div style="flex: 1 1 auto;"></div>
        </v-card>

        <v-row justify="center">
            <v-dialog v-model="deleteDialog"
                      max-width="320">
                <v-card>
                    <v-card-title class="text-h5">
                        {{ $t('admin_dashboard_deletionapproval') }} {{emailAccount.displayName}}?
                    </v-card-title>
                    <!--<v-card-text></v-card-text>-->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error"
                               @click="deleteEmailAccount()">
                            <v-icon right>
                                mdi-delete
                            </v-icon>
                            {{ $t('common_delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="deleteDialog = !deleteDialog">
                            {{ $t('common_cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!--snackbar-->
        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar">
                {{ text }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="pink"
                           text
                           v-bind="attrs"
                           @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
    import emailAccountService from '@/services/emailAccountService';
    import router from '@/router';

    export default {
        name: 'EditEmailAccount',
        props: ["emailAccountId"],
        components: {
        },
        data: () => ({
            snackbar: false,
            text: `Du kan inte radera detta konton. Minst ett konto kr�vs.`,

            deleteDialog: false,

            emailAccount: {
                id: 0,
                email: '',
                displayName: '',
                Host: '',
                port: 0,
                username: '',
                password: '',
                enableSsl: false,
                useDefaultCredentials: false,
            }
        }),
        methods: {
            emitCloseDialog() {
                router.go(-1)
            },
            deleteEmailAccount() {
                emailAccountService.deleteEmailAccount(this.emailAccountId)
                    .then(data => {
                        if (data.data) {
                            router.go(-1);
                        } else {
                            this.snackbar = true;
                        }
                    });
            },
            saveEmailAccount() {
                if (this.emailAccountId > 0) {
                    emailAccountService.updateEmailAccount({
                        id: this.emailAccountId,
                        email: this.emailAccount.email,
                        displayName: this.emailAccount.displayName,
                        host: this.emailAccount.host,
                        port: this.emailAccount.port,
                        username: this.emailAccount.username,
                        password: this.emailAccount.password,
                        enableSsl: this.emailAccount.enableSsl,
                        useDefaultCredentials: this.emailAccount.useDefaultCredentials
                    })
                        .then(data => {
                            router.go(-1)
                        });
                }
                else {
                    emailAccountService.create({
                        id: this.emailAccountId,
                        email: this.emailAccount.email,
                        displayName: this.emailAccount.displayName,
                        host: this.emailAccount.host,
                        port: this.emailAccount.port,
                        username: this.emailAccount.username,
                        password: this.emailAccount.password,
                        enableSsl: this.emailAccount.enableSsl,
                        useDefaultCredentials: this.emailAccount.useDefaultCredentials
                    })
                        .then(data => {
                            router.go(-1)
                        });
                }
            },
            fillView(data) {
                if (data) {
                    //console.log(data);
                    this.emailAccount = data.emailAccount;
                }
            }
        },
        mounted() {
            if (this.emailAccountId > 0) {
                emailAccountService.getEmailAccount(this.emailAccountId)
                    .then(data => {
                        this.fillView(data);
                    });
            }
        }
    }
</script>